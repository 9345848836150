<div *ngIf="!nav.cookieEnabled">
  <h2>Please enable cookies in order to view this site.</h2>
</div>
<div *ngIf="nav.cookieEnabled" id="universe" class="universe">
  <div class="universe-content">
    <div id="outlet" class="outlet">
      <router-outlet></router-outlet>
      <!-- active component will be rendered here-->

      <!-- Overlays -->
      <pc-status-overlay></pc-status-overlay>
      <app-multi-status-overlay></app-multi-status-overlay>
    </div>
  </div>
  <!-- end of content -->
</div>
<!-- end of universe -->

<!-- maintenence  -->
<flex-col-center-center
  style="background: white; position: fixed; left: 0; top: 0; width: 100vw; height: 100vh; z-index: 4"
  *ngIf="
    (app.environment.frontend === 'production' && app.content.content.maintenance?.fields.maintenanceModeProduction) ||
    (app.environment.frontend === 'dev' && app.content.content.maintenance?.fields.maintenanceModeDevelopment) ||
    (app.environment.frontend === 'staging' && app.content.content.maintenance?.fields.maintenanceModeStaging)
  ">
  <app-public-header></app-public-header>

  <flex-col-center-center style="width: 100%; max-width: 980px; padding: 40px; box-sizing: border-box">
    <h1 style="font-family: source-sans; font-weight: 500">
      {{ 'APP.MAINTENANCE_MESSAGE' | translate }}
    </h1>
    <div class="markdown" [innerHtml]="app.content.content.maintenance?.fields.maintenanceReason | markdown" style="font-size: 24px"></div>

    <pc-button
      [text]="'APP.MAINTENANCE_ANNOUNCEMENTS' | translate"
      (btnClick)="ui.openURL(app.content.content.maintenance?.fields.announcementLink)"></pc-button>
  </flex-col-center-center>
</flex-col-center-center>

<!-- link for more information about degraded performance has yet to be added to en-GB.json -->
<pc-information-banner
  *ngIf="proficloud.showInformationBanner$"
  [config]="{
    title: 'NAV.SLOW_CONNECTION' | translate,
    message: 'NAV.DEGRADED_PERFORMANCE_INFO' | translate,
    buttonVisibility: true,
    buttonText: 'NAV.MORE_INFORMATION' | translate,
    buttonLink: 'NAV.MORE_INFORMATION_LINK' | translate,
    closeable: true,
    showInProduction: false
  }"
  (closeClick)="proficloud.showInformationBanner$ = false"></pc-information-banner>
