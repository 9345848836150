// angular
import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { DefaultLangChangeEvent, TranslateService } from '@ngx-translate/core'
import { TracingService } from 'src/app/modules/shared/services/tracing/tracing.service'
// app
import { environment } from '@environments/environment'
import { getAppName, ProficloudService } from '@services/proficloud.service'
import { StateService } from '@services/state/state.service'
import { UiService } from '@services/ui/ui.service'
import { KeycloakService } from 'keycloak-angular'
import { AppService } from './app.service'
import { traced } from './modules/shared/decorators/trace-decorator'
import { ClassDoc } from './modules/shared/services/tracing/tracing.interfaces'
import { sharedAnimations } from './modules/shared/shared.animations'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [sharedAnimations.widthSlide, sharedAnimations.heightSlide],
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  /**
   *      Class Doc
   */
  classDoc: ClassDoc = {
    name: 'AppComponent',
    location: '/src/app/app.component.ts',
  }

  environment = environment
  nav: Navigator

  /**
   *     Constructor
   */
  constructor(
    // angular
    private title: Title,
    public cd: ChangeDetectorRef,
    public router: Router,
    // app
    public app: AppService,
    public ui: UiService,
    public tracing: TracingService,
    public proficloud: ProficloudService,
    public translate: TranslateService,
    public keycloak: KeycloakService,
    public state: StateService
  ) {
    this.constructAppComponent()
    this.setupSetTitleNavEvent()
    this.setupSetTitleLangEvent()
    this.nav = navigator
  }

  private setupSetTitleNavEvent() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.setupSetTitle()
      }
    })
  }

  private setupSetTitleLangEvent() {
    this.translate.onDefaultLangChange.subscribe((event: DefaultLangChangeEvent) => {
      this.setupSetTitle()
    })
  }

  private setupSetTitle() {
    const titles = this.getTitle(this.router.routerState, this.router.routerState.root)
    this.title.setTitle(titles.join(' | '))
    this.ui.routeTitle = titles[titles.length - 2]
    this.ui.routeSubTitle = titles[titles.length - 1]
    this.ui.routeServiceId = this.getServiceId(this.router.routerState, this.router.routerState.root)[0]
  }

  private getTitle(state: any, parent: ActivatedRoute) {
    // state is actually a RouterState but it complains about firstChild
    const data: string[] = []
    if (parent?.snapshot?.data?.title) {
      this.translate.get(parent.snapshot.data.title).subscribe((title) => {
        data.push(title)
      })
    }

    if (state && parent) {
      data.push(...this.getTitle(state, state.firstChild(parent)))
    }

    return data
  }

  private getServiceId(state: any, parent: ActivatedRoute): string[] {
    const serviceId: string[] = []
    if (parent?.snapshot?.data?.id) {
      this.translate.get(parent.snapshot.data.id).subscribe((id: string) => {
        // data.push(id)
        serviceId.push(id)
      })
    } else {
      if (state && parent) {
        serviceId.push(...this.getServiceId(state, state.firstChild(parent)))
      }
    }
    return serviceId
  }

  @traced()
  constructAppComponent() {
    this.tracing.registerInstance(this)
  }

  ngOnInit(): void {
    const appName = getAppName()
    if (appName === 'chargeRepayGateway') {
      /**
       *   skip auth setup for charge repay gateway app
       */
      return
    }
  }
}
