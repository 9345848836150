// angular
import { DragDropModule } from '@angular/cdk/drag-drop'
import { DatePipe, DecimalPipe, LOCATION_INITIALIZED, registerLocaleData } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http'
import localeDe from '@angular/common/locales/de'
import localeDeExtra from '@angular/common/locales/extra/de'
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, Injector, LOCALE_ID, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
// Translation
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
// Sentry
import * as Sentry from '@sentry/angular-ivy'
// environment
// routing
import { AppRoutingModule } from './app-routing.module'
// root component
import { AppComponent } from './app.component'
import { AppService } from './app.service'
import { ProficloudRoutingModule } from './modules/proficloud/proficloud-routing.module'
import { ProficloudModule } from './modules/proficloud/proficloud.module'
import { SharedRoutingModule } from './modules/shared/shared-routing.module'
// modules
import { environment } from '@environments/environment'
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular'
import { NgxDropzoneModule } from 'ngx-dropzone'
import { SharedModule } from './modules/shared/shared.module'
import { TokenInterceptor } from './token.interceptor'

/*
Checks token and refresh token stored in local storage.
Library checks validity and runs login during initialization if necessary
 */
function initializeKeycloak(keycloak: KeycloakService, appService: AppService) {
  const token = localStorage['access_token']
  const refreshToken = localStorage['refresh_token']

  const oauthEndpoints = {
    local: 'https://auth.proficloud-dev.io/auth',
    dev: 'https://auth.proficloud-dev.io/auth',
    staging: 'https://auth.proficloud-staging.io/auth',
    production: 'https://auth.proficloud.io/auth',
  }

  return async () => {
    await keycloak
      .init({
        config: {
          url: oauthEndpoints[appService.environment.backend],
          realm: 'kaa',
          clientId: appService.application.keycloakClientId,
        },
        initOptions: {
          enableLogging: true,
          checkLoginIframe: false,
          token: token || '',
          refreshToken: refreshToken || '',
        },
      })
      .catch((err) => {
        console.error(`Login failed. Session invalid.`)
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
      })

    const instance = keycloak.getKeycloakInstance()
    if (instance.token && instance.refreshToken) {
      localStorage.setItem('access_token', instance.token)
      localStorage.setItem('refresh_token', instance.refreshToken)
    }
  }
}

registerLocaleData(localeDe, 'de-DE', localeDeExtra)

// detect environment to include or exclude sentry error handling
const liveEnvs = ['app.proficloud.io', 'app.proficloud-dev.io', 'app.charge-repay.io', 'app.proficloud-io.cn', 'app.proficloud-dev.cn']

// Do not change the release or environment values!
// These values need to fit to whatever is setup inside the build config for sentry

if (liveEnvs.indexOf(window.location.hostname) >= 0) {
  Sentry.init({
    dsn: 'https://51dba394324f4461a00d8d20ac9e68cb@o136722.ingest.sentry.io/5201311',
    // integrations: [Sentry.replayIntegration()],
    // Session Replay
    // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    // replaysOnErrorSampleRate: 1.0,
    environment: environment.sentryEnv.toString(),
    release: `${environment.sentryEnv.toString()}-${environment.version.toString()}`,
  })
}

// Translation Loader export function
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json')
}

// This is required in order to preload (use()) the translations. Without this, refreshing proficloud (F5) would lead to
// untranslated strings
export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null))
      locationInitialized.then(() => {
        // Define possible languages here
        const en = 'en-GB'
        const de = 'de-DE'
        translate.addLangs([en, de])

        // Define english as default language
        translate.setDefaultLang(en)
        translate.use(en).subscribe({
          next: () => {
            console.log(`Successfully initialized '${en}' language.'`)
          },
          error: (err) => {
            console.error(`Problem with '${en}' language initialization.'`)
          },
          complete: () => {
            resolve(null)
          },
        })
      })
    })
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    // angular
    BrowserModule,
    BrowserAnimationsModule,
    // http
    HttpClientModule,
    // material
    // our top level routing
    AppRoutingModule,
    // shared module
    SharedModule,
    SharedRoutingModule,
    // proficloud module
    ProficloudModule,
    ProficloudRoutingModule,
    // tooltip
    DragDropModule,
    NgxDropzoneModule,
    // localization
    TranslateModule.forRoot({
      defaultLanguage: 'en-GB',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    KeycloakAngularModule,
  ],
  providers: [
    DecimalPipe,
    DatePipe,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, AppService],
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
