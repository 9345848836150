import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const blacklist = ['auth/realms/kaa', '/upload-csv', 'admin/api/firmware', '/update-management/api/v3/applications', '/api/login']
    const blacklistRegex = [/\/api\/v1\/groups\/+.+\/image+/]

    const token = localStorage.getItem('access_token')
    if (token && !blacklist.some((url) => request.url.includes(url)) && !blacklistRegex.some((regex) => regex.test(request.url))) {
      const newRequest = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Access-Control-Allow-Headers': 'Content-Type',
        },
      })
      return next.handle(newRequest)
    }
    return next.handle(request)
  }
}
